import { environment } from '../../../environments/environment';
import { newTracker, enableActivityTracking } from '@snowplow/browser-tracker';
import {
  enableFormTracking,
  FormTrackingPlugin,
} from '@snowplow/browser-plugin-form-tracking';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';
import { trackPageView } from '@snowplow/browser-tracker';

function init() {
  if (environment.snowplow_collector_url !== '') {
    newTracker('sp1', environment.snowplow_collector_url, {
      appId: environment.snowplow_app_id,
      platform: 'web', // plataforma de onde será coletado os dados
      discoverRootDomain: true, // permite que o tracker acesse o dominio da aplicação automaticamente com o configCookieDomain
      contexts: {
        webPage: true,
      },
      plugins: [FormTrackingPlugin(), LinkClickTrackingPlugin()],
    });

    enableActivityTracking({
      // habilita os eventos de page_ping
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });

    enableLinkClickTracking(); // habilita os eventos de link_click
  }
}

/*
  .:: Documentação ::.

  options: {
    forms: {
      allowlist: [],  => define quais forms serão trackeados
      denylist: [] => define quais forms não serão trackeados
    },
    fields: {
      allowlist: [], => define quais campos serão trackeados
      denylist: [], define quais campos não serão trackeados
      filter: function (ele) {
        return ele.id !== "valor"; => filtra por algum elemento do form
      },
      transform: function (value, ele) { => transforma o valor do campo
        // pode criar alguma condição
        return value;
      }
    }
  }
*/

function formTracking(options?) {
  // habilita os eventos gerados ao interagir com um formulário
  enableFormTracking({ options });
}

export { init, formTracking, trackPageView };
