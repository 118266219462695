import { Component } from '@angular/core';

import { BaseMenuItemComponent } from '../base-menu-item.component';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';

@Component({
  selector: 'indique-um-amigo-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss'],
})
export class IndiqueUmAmigoMenuItem extends BaseMenuItemComponent {
  menuCollapsed: boolean;

  constructor(menuSharedService: MenuSharedService, menuUtilsService: MenuUtilsService) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Indique um amigo',
      '/pages/referral',
      [],
      `<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3502 17.1299C11.5902 17.8199 10.4202 17.8199 9.66015 17.1199L9.55015 17.0199C4.30015 12.2699 0.870153 9.15992 1.00015 5.27992C1.06015 3.57992 1.93015 1.94992 3.34015 0.989922C5.98015 -0.810078 9.24015 0.0299218 11.0002 2.08992C12.7602 0.0299218 16.0202 -0.820078 18.6602 0.989922C20.0702 1.94992 20.9402 3.57992 21.0002 5.27992C21.1402 9.15992 17.7002 12.2699 12.4502 17.0399L12.3502 17.1299Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    );

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
      } else {
        this.showChildren = false;
      }
    });
  }

  async ngOnInit() {
    this.menuSharedService.getMenuCollapsed().subscribe((menuCollapsed) => {
      this.menuCollapsed = menuCollapsed;
      this.heightWhenShowingChildren = menuCollapsed
        ? '0px'
        : this.heightWhenShowingChildren;
    });
  }
}
