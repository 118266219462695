export enum TPersonEnum {
  PERSON = 'person',
  ENTITY = 'entity',
}

export enum TReceiveSourceEnum {
  PLANOS = 'planos',
  CREDIT_DEBIT_PIX = 'credit_debit_pix',
}

export const AccountTypes = [
  {
    id: 'd857e878-edb6-4544-9fd1-298a51e8be1e',
    description: 'Conta Corrente',
    external_id: 'checking',
  },
  {
    id: '49d6bcc5-e541-4244-94a9-72e6224565a7',
    description: 'Conta Poupança',
    external_id: 'savings',
  },
];

export interface IOption {
  id: string;
  name: string;
}

export enum TOnboardingScreen {
  STEP_ONBOARDING = 'step_onboarding',
  ORGANIZATION_ONBOARDING = 'organization_onboarding',
  USER_ONBOARDING = 'user_onboarding',
  RECEIVE_SOURCE_ONBOARDING = 'receive_source_onboarding',
  PLAN_ONBOARDING = 'plan_onboarding',
  LOGIN_DATA_ONBOARDING = 'login_data_onboarding',
  COMPANY_ONBOARDING = 'company_onboarding',
  PERSON_TYPE_ONBOARDING = 'person_type_onboarding',
  PERSON_ONBOARDING = 'person_onboarding',
  ADDRESS_ONBOARDING = 'address_onboarding',
  ACCOUNT_ONBOARDING = 'account_onboarding',
}

export enum TSteps {
  DASHBOARD = 'dashboard',
  ADDRESS = 'address',
  ACCOUNT = 'account',
  DOCUMENTS = 'documents',
  PLAN = 'plan',
  FINISHED_REGISTER = 'finished_register',
  OPERATIONAL_AGREEMENT = 'operational_agreement',
  SEGMENTATION = 'segmentation',
}
