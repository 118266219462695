import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, RequestOptions, XHRBackend } from '@angular/http';
import { Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AbTestsModule } from 'angular-ab-tests';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng2CompleterModule } from 'ng2-completer';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import { DetailComponent } from '../pages/convenios/lote/components/detail.component';
import { LoteWebService } from '../pages/convenios/lote/services/lote.web.service';
import { DetectDeviceService } from '../shared/services/detect-device.service';
import { ThemeSharedService } from '../theme/services/theme.shared.service';
import { ActionButtonComponent } from './components/atoms/action-button/action-button.component';
import { CardComponent } from './components/atoms/card/card.component';
import { CopyrightComponent } from './components/atoms/copyright/copyright.component';
import { LogoComponent } from './components/atoms/logo/logo.component';
import { RadioButtonComponent } from './components/atoms/radio-button/radio-button.component';
import { SubtitleComponent } from './components/atoms/subtitle/subtitle.component';
import { TitleComponent } from './components/atoms/title/title.component';
import { ExpandableRadioComponent } from './components/molecules/expandable-radio/expandable-radio.component';
import { RetrospectiveCardComponent } from './components/molecules/retrospective-card/retrospective-card.component';
import { TextHeadingComponent } from './components/molecules/text-heading/text-heading.component';
import { BannerComponent } from './components/refactor-components/banner/banner.component';
import { DeleteModalComponent } from './components/refactor-components/delete-modal/delete-modal.component';
import { DrawerComponent } from './components/refactor-components/drawer/drawer.component';
import { ExportLoadingComponent } from './components/refactor-components/export-loading/export-loading.component';
import { GenericError } from './components/refactor-components/generic-error/generic-error.component';
import { IcpOnboardingComponent } from './components/refactor-components/layouts/icp-onboarding/icp-onboarding.component';
import { IcpSplittedScreenComponent } from './components/refactor-components/layouts/icp-splitted-screen/icp-splitted-screen.component';
import { LoadingComponent } from './components/refactor-components/loading/loading.component';
import { PaginationComponent } from './components/refactor-components/pagination/pagination.component';
import { PlansModalComponent } from './components/refactor-components/plans-modal/plans-modal.component';
import { SubscriptionLimitComponent } from './components/refactor-components/subscription-limit/subscription-limit.component';
import { IcpAlertItemComponent } from './components/refactor-components/ui/icp-alert/icp-alert-item/icp-alert-item.component';
import { IcpAlertComponent } from './components/refactor-components/ui/icp-alert/icp-alert.component';
import { IcpCarouselComponent } from './components/refactor-components/ui/icp-carousel/icp-carousel.component';
import { IcpFileUploadComponent } from './components/refactor-components/ui/icp-file-upload/icp-file-upload.component';
import { IcpImageWithCaptionComponent } from './components/refactor-components/ui/icp-image-with-caption/icp-image-with-caption.component';
import { IcpInputComponent } from './components/refactor-components/ui/icp-input/icp-input.component';
import { IcpProgressBarComponent } from './components/refactor-components/ui/icp-progress-bar/icp-progress-bar.component';
import { IcpRadioButtonComponent } from './components/refactor-components/ui/icp-radio-button/icp-radio-button.component';
import { IcpSelectComponent } from './components/refactor-components/ui/icp-select/icp-select.component';
import { IcpSelectService } from './components/refactor-components/ui/icp-select/icp-select.service';
import { IcpTableComponent } from './components/refactor-components/ui/icp-table/icp-table.component';
import { LoadingButtonComponent } from './directives/loading-button.directive';
import { LoadingChartComponent } from './directives/loading-chart.directive';
import { LoadingContentComponent } from './directives/loading-content.directive';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { SortTableDirective } from './directives/sort-table.directive';
import { SpinnerComponent } from './directives/spinner.directive';
import { HttpInterceptor } from './interceptors/http-interceptor.service';
import { PipeModule } from './pipes/pipe.module';
import { IntercomService } from './services/intercom.service';
import { RouteInterceptorService } from './services/route-interceptor.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextMaskModule,
    HttpClientModule,
    HttpModule,
    Ng2CompleterModule,
    AbTestsModule,
    PipeModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    LoadingButtonComponent,
    LoadingContentComponent,
    LoadingChartComponent,
    DeleteModalComponent,
    ExportLoadingComponent,
    SpinnerComponent,
    TextMaskModule,
    Ng2CompleterModule,
    AbTestsModule,
    SubscriptionLimitComponent,
    PipeModule,
    OnlynumberDirective,
    GenericError,
    PaginationComponent,
    IcpSelectComponent,
    LoadingComponent,
    BannerComponent,
    DrawerComponent,
    SortTableDirective,
    DetailComponent,
    IcpTableComponent,
    IcpCarouselComponent,
    IcpSplittedScreenComponent,
    IcpAlertComponent,
    IcpInputComponent,
    IcpOnboardingComponent,
    IcpRadioButtonComponent,
    IcpImageWithCaptionComponent,
    IcpProgressBarComponent,
    IcpFileUploadComponent,
    TitleComponent,
    SubtitleComponent,
    TextHeadingComponent,
    ActionButtonComponent,
    ExpandableRadioComponent,
    LogoComponent,
    CopyrightComponent,
    RadioButtonComponent,
    CardComponent,
    RetrospectiveCardComponent,
  ],
  declarations: [
    LoadingButtonComponent,
    LoadingContentComponent,
    LoadingChartComponent,
    DeleteModalComponent,
    SpinnerComponent,
    ExportLoadingComponent,
    SubscriptionLimitComponent,
    OnlynumberDirective,
    GenericError,
    PaginationComponent,
    IcpSelectComponent,
    LoadingComponent,
    BannerComponent,
    DrawerComponent,
    SortTableDirective,
    DetailComponent,
    IcpTableComponent,
    IcpCarouselComponent,
    IcpSplittedScreenComponent,
    IcpAlertComponent,
    IcpInputComponent,
    IcpOnboardingComponent,
    IcpRadioButtonComponent,
    IcpAlertItemComponent,
    IcpImageWithCaptionComponent,
    IcpProgressBarComponent,
    IcpFileUploadComponent,
    TitleComponent,
    SubtitleComponent,
    TextHeadingComponent,
    ActionButtonComponent,
    ExpandableRadioComponent,
    LogoComponent,
    CopyrightComponent,
    RadioButtonComponent,
    CardComponent,
    RetrospectiveCardComponent,
    PlansModalComponent,
  ],
  providers: [
    RouteInterceptorService,
    LoteWebService,
    IntercomService,
    IcpSelectService,
    DetectDeviceService,
    {
      provide: HttpInterceptor,
      useFactory: newHttp,
      deps: [XHRBackend, RequestOptions, Router, ThemeSharedService, HttpClient],
    },
  ],
})
export class SharedModule {}

export function newHttp(
  backend: XHRBackend,
  defaultOptions: RequestOptions,
  routerExtensions: Router,
  themeService: ThemeSharedService,
  httpClient: HttpClient,
  intercomService: IntercomService
) {
  return new HttpInterceptor(
    backend,
    defaultOptions,
    routerExtensions,
    themeService,
    httpClient,
    intercomService
  );
}
