import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class HandleLoggedUserGuard implements CanActivate {
  public check: boolean;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (
        this.localStorage.getUser() &&
        !route.paramMap.get('email_confirmation_token')
      ) {
        this.router.navigate(['/pages/gestao-financeira/dashboard/resumo']);
        return true;
      } else {
        this.router.navigate(['/login']);
        return true;
      }
    } catch {
      return true;
    }
  }
}
