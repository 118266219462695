import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

import { LocalStorageService } from './user/services/local-storage.service';
import { EmailConfirmationService } from './user/services/email-confirmation.service';

import { ThemeSharedService } from '../theme/services/theme.shared.service';
import { MenuSharedService } from '../theme/components/menu/services/menu.shared.service';
import { ContentLoadService } from '../shared/services/content-load.service';

import { environment } from './../../environments/environment';

@Component({
  selector: 'pages',
  templateUrl: './pages.component.html',
  styles: [
    `
      .menuCollapsed {
        overflow: auto;
      }
    `,
  ],
})
export class Pages {
  menuCollapsed: boolean = true;
  cadastroWarning: boolean = false;
  emailConfirmed: boolean = false;
  emailNotConfirmed: boolean = false;
  user: any;
  showMenu: boolean = true;
  emailSent: boolean = false;
  genericErrorReceived: boolean = null;
  private alive: boolean = true;
  isMaintenancePage: boolean = true;
  showAlert: boolean;

  constructor(
    private themeService: ThemeSharedService,
    private localStorage: LocalStorageService,
    private router: Router,
    private emailConfirmationService: EmailConfirmationService,
    private menuSharedService: MenuSharedService,
    private contentLoadService: ContentLoadService
  ) {
    if (!this.localStorage.getUser()) {
      this.contentLoadService.setLoadingData(['Sem usuário logado', false]);
      const url = window.location.href.split('#');
      this.router.navigate(['login']);
      this.localStorage.setObject('urlToRedirect', url[1]);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngOnInit() {
    this.showAlert = environment.featureFlags.maintenanceAlert;

    if (environment.featureFlags.maintenancePage) {
      this.router.navigate(['/maintenance']);
    }

    this.user = this.localStorage.getUser();
    if (!this.user.data_confirmacao_email) {
      this.themeService.setEmailNotConfirmed(true);
    } else {
      this.themeService.setEmailNotConfirmed(false);
    }
    this.menuSharedService.getMenuCollapsed().subscribe((res) => {
      this.menuCollapsed = res;
    });
    this.themeService.getEmailConfirmed().subscribe((res) => {
      this.emailConfirmed = res;
    });
    this.themeService.getEmailNotConfirmed().subscribe((res) => {
      this.emailNotConfirmed = res;
    });
    this.themeService.getGenericErrorHandler().subscribe((res) => {
      this.genericErrorReceived = res;
    });
    this.router.events.pipe(takeWhile(() => this.alive)).subscribe((event: any) => {
      if (event.url && event.url !== '/logout') {
        if (!this.user.data_confirmacao_email) {
          this.themeService.setEmailNotConfirmed(true);
        } else {
          this.themeService.setEmailNotConfirmed(false);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.themeService.getHideMenu().subscribe((res) => {
      setTimeout(() => {
        this.showMenu = !!res;
      }, 1);
    });
  }

  resendEmail() {
    return this.emailConfirmationService.resendEmail().subscribe(
      (res) => {
        this.emailSent = true;
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
