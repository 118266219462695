import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PipeModule } from '../../../shared/pipes/pipe.module';
import { PlaceholderComponent } from './components/placeholder.component';
import { AntecipacoesMenuItem } from './items/antecipacoes';
import { ConfigMenuItem } from './items/configuracoes';
import { ConveniosMenuItem } from './items/convenios';
import { GestaoFinanceiraMenuItem } from './items/gestao-financeira';
import { IndiqueUmAmigoMenuItem } from './items/indique-um-amigo';
import { IclinicPayMenuItem } from './items/medicinae-pay';
import { RetrospectiveMenuItem } from './items/retrospective';
import { SimuladorDeVendasMenuItem } from './items/simulador-de-vendas';
import { MenuUtilsService } from './services/menu-utils.service';
import { MenuSharedService } from './services/menu.shared.service';

@NgModule({
  imports: [PipeModule, CommonModule, RouterModule],
  exports: [
    IclinicPayMenuItem,
    SimuladorDeVendasMenuItem,
    GestaoFinanceiraMenuItem,
    ConveniosMenuItem,
    IndiqueUmAmigoMenuItem,
    PlaceholderComponent,
    ConfigMenuItem,
    AntecipacoesMenuItem,
    RetrospectiveMenuItem,
  ],
  declarations: [
    IclinicPayMenuItem,
    SimuladorDeVendasMenuItem,
    GestaoFinanceiraMenuItem,
    ConveniosMenuItem,
    IndiqueUmAmigoMenuItem,
    PlaceholderComponent,
    ConfigMenuItem,
    AntecipacoesMenuItem,
    RetrospectiveMenuItem,
  ],
  providers: [MenuSharedService, MenuUtilsService],
})
export class MenuModule {}
