import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'optiondisplay' })
export class OptionDisplayPipe implements PipeTransform {
  transform(value: object, displayMember: string = 'description') {
    if (value) {
      return value[displayMember];
    } else {
      return '';
    }
  }
}
