import './shared/utils/rxjs-operators';

import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { AbTestsModule } from 'angular-ab-tests';
import { IntercomModule } from 'ng-intercom';
import { MatomoModule } from 'ngx-matomo';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { VendedorService } from './pages/dados-cadastrais/particular/services/vendedor.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PagesModule } from './pages/pages.module';
import { FullScreenLoadingComponent } from './shared/components/refactor-components/full-screen-loading/full-screen-loading.component';
import { CacheService } from './shared/services/cache.service';
import { ContentLoadService } from './shared/services/content-load.service';
import { SentryErrorHandler } from './shared/services/sentry-error-handler.service';
import * as Snowplow from './shared/services/snowplow.service';
import { SharedModule } from './shared/shared.module';
import { OutdatedBrowserWarningComponent } from './theme/components/outdated-browser-warning/outdated-browser-warning.component';

Snowplow.init();

if (environment.name !== 'local') {
  Sentry.init({
    dsn: 'https://547482113a32465fa42edb3fe4649a9f@o253472.ingest.sentry.io/1442178',
    environment: environment.name,
    beforeSend(event) {
      if (event.stacktrace) {
        return;
      }
      return event;
    },
  });

  Sentry.configureScope((scope) => {
    scope.setTag('idioma', 'pt-BR');
  });
}

if (localStorage && localStorage['user'] && environment.name !== 'local') {
  try {
    const user = JSON.parse(localStorage['user']);
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
      });
    });
  } catch (e) {}
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    FullScreenLoadingComponent,
    OutdatedBrowserWarningComponent,
    MaintenanceComponent,
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    BrowserModule,
    RouterModule,
    PagesModule,
    routing,
    MatomoModule,
    ToastrModule.forRoot(),
    AbTestsModule.forRoot([
      {
        versions: ['v1', 'v2'],
        scope: 'mudancaPlanoAntecipacao',
        expiration: 30,
      },
    ]),
    IntercomModule.forRoot({
      appId: environment.intercom_app_id,
      updateOnRouterChange: true,
    }),
  ],
  providers: [
    CacheService,
    VendedorService,
    ContentLoadService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
