import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { first, map } from 'rxjs/operators';

import { TSteps } from './../types/onboarding.types';
import { OnboardingService } from '../services/onboarding.service';
import { TOnboardingScreen } from '../types';

import { CacheService } from '../../shared/services/cache.service';
import { StepService } from '../../shared/services/step.service';
import { OrganizacaoOnboardingStep } from '../../pages/user/models/organizacao.model';

@Injectable()
export class OnboardingRouterGuard implements CanActivate {
  constructor(
    private router: Router,
    private cacheService: CacheService,
    private stepService: StepService,
    private onboardingService: OnboardingService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkGuardAccess();
  }

  checkGuardAccess() {
    return this.cacheService.getRouterGuardData().pipe(
      map((response) => {
        const data = response.data as OrganizacaoOnboardingStep;

        if (data.step === TSteps.FINISHED_REGISTER.toUpperCase()) {
          return true;
        }

        if (data.step === TSteps.OPERATIONAL_AGREEMENT.toUpperCase()) {
          const routerLink = this.stepService.getRouterLink(data.step, data.seller_id);

          if (routerLink.sellerId) {
            this.router.navigate([routerLink.url], {
              queryParams: { vendedorId: routerLink.sellerId },
            });
            return true;
          }

          this.router.navigate([routerLink.url]);

          return true;
        }

        const keys = Object.keys(this.stepService.routerNavigate);
        const isOnboarding =
          keys.includes(data.step) &&
          data.physician_health_plan_amount === 0 &&
          data.plan_amount === 0 &&
          data.physician_health_plan_amount === 0;

        if (isOnboarding) {
          this.onboardingService.setData({
            [TOnboardingScreen.STEP_ONBOARDING]: data,
          });

          this.router.navigate(['/onboarding/etapas']);
          return true;
        }

        return true;
      }),
      first()
    );
  }
}
