import { MenuItemChild } from './models/menu-item-child.model';
import { MenuUtilsService } from './services/menu-utils.service';
import { MenuSharedService } from './services/menu.shared.service';

export abstract class BaseMenuItemComponent {
  title: string;
  route: string;
  children: MenuItemChild[];
  icon: string;
  active: boolean;
  showChildren: boolean;
  heightWhenShowingChildren: string;

  // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
  constructor(
    title: string,
    route: string,
    children: MenuItemChild[],
    icon: string,
    active: boolean,
    showChildren: boolean,
    heightWhenShowingChildren: string,
    protected menuSharedService: MenuSharedService,
    protected menuUtilsService: MenuUtilsService
  ) {
    this.title = title;
    this.route = route;
    this.children = children;
    this.icon = icon;
    this.showChildren = showChildren;
    this.active = active;
    this.heightWhenShowingChildren = heightWhenShowingChildren;
  }

  // Função para lidar com a exibição dos subitems
  toggleItemChildren() {
    if (this.children.length) {
      this.menuSharedService.setSubItemsOf(this.title);
      this.menuSharedService.setMenuCollapsed(false);
    }
  }

  menuItemClicked() {
    this.menuSharedService.setMenuCollapsed(true);
    this.menuSharedService.setSubItemsOf('');
  }
}
