import { Component, OnInit } from '@angular/core';
import { ContentLoadService } from '../../../services/content-load.service';
import { trigger, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'full-screen-loading',
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('enterAnimationOkMessage', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
  templateUrl: './full-screen-loading.component.html',
  styleUrls: ['./full-screen-loading.component.scss'],
})
export class FullScreenLoadingComponent implements OnInit {
  showLoading: boolean = false;
  loadingFinished: boolean = false;
  message: string;

  constructor(private contentLoadService: ContentLoadService) {}

  ngOnInit() {
    this.contentLoadService.getLoadingData().subscribe(([message, showLoading]) => {
      this.message = message;
      this.showLoading = showLoading;
      this.loadingFinished = false;
      setTimeout(() => {
        this.loadingFinished = true;
      }, 2000);
    });
  }
}
