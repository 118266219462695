export class Lote {
  id: string;
  numero: string;
  data: string;
  data_pagamento_demonstrativo: string;
  valor_informado?: number;
  valor_liberado: number;
  emprestado: string;
  medico_plano_saude: any;
  data_pagamento_calculada: string;
  valor_glosa_calculado: any;
  plano_saude: string;
  valor_glosa: string;
  valor_disponibilizado: string;
  qtd_guias: number;

  constructor(
    id?: string,
    numero?: string,
    data?: string,
    data_pagamento_demonstrativo?: string,
    valor_informado?: number,
    valor_liberado?: number,
    emprestado?: string,
    medico_plano_saude?: any,
    data_pagamento_calculada?: string,
    valor_glosa_calculado?: any,
    plano_saude?: string,
    valor_glosa?: string,
    valor_disponibilizado?: string,
    qtd_guias?: number
  ) {
    this.id = id;
    this.numero = numero;
    this.data = data;
    this.data_pagamento_demonstrativo = data_pagamento_demonstrativo;
    this.valor_informado = valor_informado;
    this.valor_liberado = valor_liberado;
    this.emprestado = emprestado;
    this.medico_plano_saude = medico_plano_saude;
    this.data_pagamento_calculada = data_pagamento_calculada;
    this.valor_glosa_calculado = valor_glosa_calculado;
    this.plano_saude = plano_saude;
    this.valor_glosa = valor_glosa;
    this.valor_disponibilizado = valor_disponibilizado;
    this.qtd_guias = qtd_guias;
  }

  getQtdGuias() {
    return this.qtd_guias < 10 ? `0${this.qtd_guias}` : this.qtd_guias;
  }
}
