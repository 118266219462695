export class BaseRoutes {
  private _BASE: string;
  private _CREATE: string;
  private _EDIT: string;
  private _LIST: string;
  private _LIST_PESSOAL: string;
  private _LIST_PLANO: string;
  private _CREATE_PESSOAL: string;
  private _CREATE_PLANO: string;
  private _EDIT_PESSOAL: string;
  private _EDIT_PLANO: string;
  private _ACORDO_OPERACIONAL: string;
  private _CADASTRO_UNIDADE_COMPLETO: string;
  private _CADASTRO_GERAL_COMPLETO: string;

  constructor(route: string) {
    this._BASE = route;
    this._CREATE = this.appendPath(this.BASE, 'create/');
    this._EDIT = this.appendPath(this.BASE, 'edit/');
    this._LIST = this.appendPath(this.BASE, 'list/');
    this._LIST_PESSOAL = this.appendPath(this.BASE, 'list-pessoal/');
    this._LIST_PLANO = this.appendPath(this.BASE, 'list-plano/');
    this._CREATE_PESSOAL = this.appendPath(this.BASE, 'create-pessoal/');
    this._CREATE_PLANO = this.appendPath(this.BASE, 'create-plano/');
    this._EDIT_PESSOAL = this.appendPath(this.BASE, 'edit-pessoal/');
    this._EDIT_PLANO = this.appendPath(this.BASE, 'edit-plano/');
    this._ACORDO_OPERACIONAL = this.appendPath(this.BASE, 'acordo-operacional/');
    this._CADASTRO_UNIDADE_COMPLETO = this.appendPath(this.BASE, 'cadastro-unidade/');
    this._CADASTRO_GERAL_COMPLETO = this.appendPath(this.BASE, 'cadastro-geral/');
  }

  get BASE() {
    return this._BASE;
  }

  get CREATE() {
    return this._CREATE;
  }

  get EDIT() {
    return this._EDIT;
  }

  get LIST() {
    return this._LIST;
  }

  get LIST_PESSOAL() {
    return this._LIST_PESSOAL;
  }

  get LIST_PLANO() {
    return this._LIST_PLANO;
  }

  get CREATE_PESSOAL() {
    return this._CREATE_PESSOAL;
  }

  get CREATE_PLANO() {
    return this._CREATE_PLANO;
  }

  get EDIT_PESSOAL() {
    return this._EDIT_PESSOAL;
  }

  get EDIT_PLANO() {
    return this._EDIT_PLANO;
  }

  get ACORDO_OPERACIONAL() {
    return this._ACORDO_OPERACIONAL;
  }

  get CADASTRO_UNIDADE_COMPLETO() {
    return this._CADASTRO_UNIDADE_COMPLETO;
  }

  get CADASTRO_GERAL_COMPLETO() {
    return this._CADASTRO_GERAL_COMPLETO;
  }

  preAppend(route: string) {
    this._BASE = this.appendPath(route, this._BASE);
    this._CREATE = this.appendPath(route, this._CREATE);
    this._EDIT = this.appendPath(route, this._EDIT);
    this._LIST = this.appendPath(route, this._LIST);
    this._LIST_PESSOAL = this.appendPath(route, this._LIST_PESSOAL);
    this._LIST_PLANO = this.appendPath(route, this._LIST_PLANO);
    this._CREATE_PESSOAL = this.appendPath(route, this._CREATE_PESSOAL);
    this._CREATE_PLANO = this.appendPath(route, this._CREATE_PLANO);
    this._EDIT_PESSOAL = this.appendPath(route, this._EDIT_PESSOAL);
    this._EDIT_PLANO = this.appendPath(route, this._EDIT_PLANO);
    this._ACORDO_OPERACIONAL = this.appendPath(route, this._ACORDO_OPERACIONAL);
    this._CADASTRO_UNIDADE_COMPLETO = this.appendPath(
      route,
      this._CADASTRO_UNIDADE_COMPLETO
    );
    this._CADASTRO_GERAL_COMPLETO = this.appendPath(route, this._CADASTRO_GERAL_COMPLETO);
  }

  public appendPath(pathA: string, pathB: string) {
    return pathA + '/' + pathB;
  }
}
