import { formatDateToSend } from './formatter';

export function brazillianDateToISO(date: string) {
  return date.split('/').reverse().join('-');
}

export function ISOToBrazillianDate(date: string) {
  return date.split('-').reverse().join('/');
}

export function getOnlyDigits(text: string) {
  return text.replace(/\D/g, '');
}

export function capitalizeString(text: string) {
  let splittedText = text.split('');
  splittedText[0] = splittedText[0].toLocaleUpperCase();
  return splittedText.join('');
}

export function primeiroDiaDozeMesesAtras(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    date1.setMonth(date1.getMonth() - 11);
    date1.setDate(1);
    return formatDateToSend(date1);
  }
  let d = new Date();
  d.setMonth(d.getMonth() - 11);
  d.setDate(1);
  return formatDateToSend(d);
}

export const parseCurrency = (value: string): Number => {
  if (isNaN(Number(value))) {
    const [integer, decimal] = value.split(',');
    return Number(integer.replace(/\D/g, '') + '.' + decimal);
  } else {
    return Number(value);
  }
};

export function primeiroDiaTrezeMesesAtras(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    date1.setMonth(date1.getMonth() - 12);
    date1.setDate(1);
    return formatDateToSend(date1);
  }
  let d = new Date();
  d.setMonth(d.getMonth() - 12);
  d.setDate(1);
  return formatDateToSend(d);
}

export function primeiroDiaQuatorzeMesesAtras(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    date1.setMonth(date1.getMonth() - 12);
    date1.setDate(1);
    return formatDateToSend(date1);
  }
  let d = new Date();
  d.setMonth(d.getMonth() - 13);
  d.setDate(1);
  return formatDateToSend(d);
}

export function ultimoDiaMesAtual(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    date1.setMonth(date1.getMonth() + 1);
    date1.setDate(1);
    date1.setDate(date1.getDate() - 1);
    return formatDateToSend(date1);
  }
  let d = new Date();
  d.setMonth(d.getMonth() + 1);
  d.setDate(1);
  d.setDate(d.getDate() - 1);
  return formatDateToSend(d);
}

export function primeiroDateTimeMesAtual(specified_date?: string) {
  const currentDate = specified_date ? new Date(specified_date) : new Date();
  const lastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
    0,
    0,
    0
  );
  return lastDate.toISOString();
}

export function ultimoDateTimeMesAtual(specified_date?: string) {
  const currentDate = specified_date ? new Date(specified_date) : new Date();
  const lastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
    0,
    0,
    0,
    -1
  );
  return lastDate.toISOString();
}

export function primeiroDiaMesAtual() {
  let d = new Date();
  d.setDate(1);
  return formatDateToSend(d);
}

export function ultimoDiaMesAnterior(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    date1.setMonth(date1.getMonth());
    date1.setDate(1);
    date1.setDate(date1.getDate() - 1);
    return formatDateToSend(date1);
  }
  let d = new Date();
  d.setMonth(d.getMonth());
  d.setDate(1);
  d.setDate(d.getDate() - 1);
  return formatDateToSend(d);
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

export function primeiroDiaMesAnterior(specified_date?: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = parseInt(specified_date.substr(5, 2));
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 2, dt1);
    date1.setDate(1);
    return formatDateToSend(date1);
  }
}

export function primeiroDiaAno(specified_date: string) {
  if (specified_date) {
    let dt1 = parseInt(specified_date.substr(8, 2));
    let mon1 = 1;
    let yr1 = parseInt(specified_date.substr(0, 4));
    let date1 = new Date(yr1, mon1 - 1, dt1);
    return formatDateToSend(date1);
  }
}

export function ultimoDiaAno(specified_date: string) {
  if (specified_date) {
    let mon1 = 1;
    let yr1 = parseInt(specified_date.substr(0, 4)) + 1;
    let date1 = new Date(yr1, mon1 - 2, 31);
    return formatDateToSend(date1);
  }
}

export function sumIntervalOfArray(array, start) {
  var total = 0;
  for (var i = start; i < array.length; i++) {
    total = total + array[i];
  }
  return total;
}

export function clearMask(text: string) {
  return text.replace(/[^0-9]/g, '');
}

export function checkEighteen(date: Date) {
  date.setFullYear(date.getFullYear() - 18);
  return date;
}

export function treatDateMask(date_string: string) {
  let dateEls = date_string.split('/');
  if (dateEls[0].length > 2) return date_string;
  else return `${dateEls[2]}-${dateEls[1]}-${dateEls[0]}`;
}

export function checkPermissionToEmprestar(localStorage) {
  let user = localStorage.getUser();
  if (
    user.permissoes.indexOf('emprestar') == -1 &&
    user.permissoes.indexOf('emprestar_total') == -1
  ) {
    return false;
  } else {
    return true;
  }
}

export function getCookie(cookiename) {
  // Get name followed by anything except a semicolon
  var cookiestring = RegExp('' + cookiename + '[^;]+').exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(
    !!cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : ''
  );
}

export function buildMonthList() {
  let dates = new Array<any>();
  let monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  for (var i = 0; i <= 11; i++) {
    let date = new Date();
    date.setDate(1);
    let x = date.getMonth() - i;
    date.setMonth(x);
    let newDate = { mes: '', data: '', ano: '', mes_numero: null };
    newDate.mes = monthNames[date.getMonth()];
    newDate.mes_numero = date.getMonth();
    newDate.ano = String(date.getFullYear());
    newDate.data = formatDateToSend(date);
    dates.push(newDate);
  }
  return dates;
}

export function sortArrayByDate(array, key) {
  return array.sort((a, b) => {
    let date1 = new Date(a[key]);
    let date2 = new Date(b[key]);
    return date1.getTime() - date2.getTime();
  });
}

export const fixedMonths = [
  { description: 'Todos', id: -1 },
  { description: 'Janeiro', id: 0 },
  { description: 'Fevereiro', id: 1 },
  { description: 'Março', id: 2 },
  { description: 'Abril', id: 3 },
  { description: 'Maio', id: 4 },
  { description: 'Junho', id: 5 },
  { description: 'Julho', id: 6 },
  { description: 'Agosto', id: 7 },
  { description: 'Setembro', id: 8 },
  { description: 'Outubro', id: 9 },
  { description: 'Novembro', id: 10 },
  { description: 'Dezembro', id: 11 },
];

export const monthNames = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function isBeforeHour(hour) {
  const currentTime = new Date();
  let date = new Date();
  date.setHours(hour);
  if (currentTime.getTime() < date.getTime()) return true;
  return false;
}

export function getArrayOfNumbersBetween(first, last): any {
  if (!first || !last)
    return new Error('You need to pass a start number and an end number.');
  const numbers = [];
  for (let i = first; i <= last; i++) {
    numbers.push(String(i));
  }
  return numbers;
}

export function checkBrazilianDateFormat(input) {
  const reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
  return !!input?.match(reg);
}

export enum PageSizes {
  MAX_ITEMS = 200,
}
