import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseMenuItemComponent } from '../base-menu-item.component';
import { MenuItemChild } from '../models/menu-item-child.model';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';

@Component({
  selector: 'plano-de-saude-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss'],
})
export class ConveniosMenuItem extends BaseMenuItemComponent {
  @Input('show-children') showChildren: boolean;
  @Output() onClickSelect = new EventEmitter<any>();
  @Output() onClickItem = new EventEmitter<void>();
  menuCollapsed: boolean;
  isMobile: boolean = false;

  constructor(menuSharedService: MenuSharedService, menuUtilsService: MenuUtilsService) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Planos de saúde',
      'planos-de-saude',
      [
        new MenuItemChild(
          'Meus planos',
          '/pages/planos-de-saude/meus-convenios/list',
          false,
          true
        ),
        new MenuItemChild('Faturas', '/pages/planos-de-saude/lote/list', false, true),
      ],
      `<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 11.0001H5.5C4.67 11.0001 4 10.3301 4 9.50007C4 8.67007 4.67 8.00007 5.5 8.00007H6.5V7.00007C6.5 6.17007 7.17 5.50007 8 5.50007C8.83 5.50007 9.5 6.17007 9.5 7.00007V8.00007H10.5C11.33 8.00007 12 8.67007 12 9.50007C12 10.3301 11.33 11.0001 10.5 11.0001H9.5V12.0001C9.5 12.8301 8.83 13.5001 8 13.5001C7.17 13.5001 6.5 12.8301 6.5 12.0001V11.0001ZM7.3 0.260066L1.3 2.51007C0.52 2.81007 0 3.55007 0 4.39007V9.09007C0 14.1401 3.41 18.8501 8 20.0001C12.59 18.8501 16 14.1401 16 9.09007V4.39007C16 3.56007 15.48 2.81007 14.7 2.52007L8.7 0.270066C8.25 0.0900658 7.75 0.0900659 7.3 0.260066Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    );

    const role = JSON.parse(localStorage.getItem('user')).role_name;
    if (!role.includes('iniciante') && !role.includes('basico')) {
      this.children.push(
        new MenuItemChild('Faturas', '/pages/planos-de-saude/lote/list', false)
      );
    }

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
        this.heightWhenShowingChildren = menuUtilsService.calculateSubmenuHeight(
          this.children
        );
      } else {
        this.showChildren = false;
        this.heightWhenShowingChildren = '0px';
      }
    });
  }

  async ngOnInit() {
    if (window.innerWidth <= 500 || screen.width <= 500) {
      this.isMobile = true;
    }

    this.menuSharedService.getMenuCollapsed().subscribe((menuCollapsed) => {
      this.menuCollapsed = menuCollapsed;
      this.heightWhenShowingChildren =
        menuCollapsed || this.isMobile ? '0px' : this.heightWhenShowingChildren;

      if (this.menuCollapsed && this.showChildren) {
        this.showChildren = false;
      }
    });
  }

  toggleItemChildren() {
    super.toggleItemChildren();
    this.onClickSelect.emit({ title: this.title, children: this.children });
    this.isMobile = window.innerWidth <= 500 || screen.width <= 500 ? true : false;
  }

  menuItemClicked() {
    super.menuItemClicked();
    this.onClickItem.emit();
  }
}
