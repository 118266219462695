import { Injectable } from '@angular/core';
import { ContentLoadMessage } from '../models/content-load-message.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ContentLoadService {
  loadingMessage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  loadingFinished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingData: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([
    null,
    null,
  ]);

  private selector: string = 'contentLoad';
  private containerSelector: string = 'contentContainer';
  private element: HTMLElement = document.createElement('contentLoad');
  private container: HTMLElement = document.createElement('contentContainer');
  private body: any;

  constructor() {
    this.element = document.getElementById(this.selector);
    this.container = document.getElementById(this.containerSelector);
    this.body = document.getElementsByTagName('body')[0];
  }

  show(message?: ContentLoadMessage): void {
    window.scrollTo(0, 0);
    this.removeMessages();
    this.element.style['display'] = 'block';
    this.body.style['overflow'] = 'hidden';
    if (message) {
      let itemToInsert = `<p class="gotham-medium"><span class="hurme">${message.status}</span>${message.message}</p>`;
      this.container.insertAdjacentHTML('beforeend', itemToInsert);
    }
  }

  hide(delay: number = 0): void {
    setTimeout(() => {
      if (this.element) {
        this.element.style['display'] = 'none';
        this.body.style['overflow'] = 'visible';
      }
      if (this.container) {
        let messages = this.container.getElementsByTagName('p');
        Array.from(messages).forEach((el) => {
          el.remove();
        });
      }
    }, delay);
  }

  removeMessages() {
    let messages = this.container.getElementsByTagName('p');
    Array.from(messages).forEach((el) => {
      el.remove();
    });
  }

  editMessage(old_message: ContentLoadMessage, new_message: ContentLoadMessage) {
    let messages = this.container.getElementsByTagName('p');
    Array.from(messages).forEach((el) => {
      if (old_message.status + old_message.message === el.innerText) {
        el.innerHTML = `<p class="gotham-medium">${new_message.message}<span class="hurme">${new_message.status}</span></p>`;
      }
    });
  }

  showLoadMessages(message?: ContentLoadMessage, loadCount?: number): void {
    if (loadCount !== 0 && loadCount > 0) {
      if (message) {
        this.show(message);
      } else {
        this.show();
      }
    }
  }

  editLoadMessages(old_message: ContentLoadMessage, new_message: ContentLoadMessage) {
    this.editMessage(old_message, new_message);
  }

  loadCountDown(
    loadCount: number,
    old_message: ContentLoadMessage,
    new_message: ContentLoadMessage
  ) {
    loadCount--;
    if (loadCount === 0) {
      this.editLoadMessages(old_message, new_message);
      this.hide(2000);
    }
  }

  changeLoadingMessage(text) {
    this.loadingMessage.next(text);
  }

  getLoadingMessage() {
    return this.loadingMessage.asObservable();
  }

  setLoadingFinished(boolean) {
    this.loadingFinished.next(boolean);
  }

  getLoadingFinished() {
    return this.loadingFinished.asObservable();
  }

  setShowLoading(boolean) {
    this.showLoading.next(boolean);
  }

  getShowLoading() {
    return this.showLoading.asObservable();
  }

  setLoadingData([message, showLoading]) {
    this.loadingData.next([message, showLoading]);
  }

  getLoadingData() {
    return this.loadingData.asObservable();
  }
}
