import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UserWebService } from './user.web.service';
import { OrganizacaoDataService } from './organizacao-data.web.service';
import { forkJoin, of } from 'rxjs';
import { CacheService } from '../../../shared/services/cache.service';
import { ThemeSharedService } from '../../../theme/services/theme.shared.service';

@Injectable()
export class UpdateUserGuard implements CanActivate {
  constructor(
    private localStorage: LocalStorageService,
    private userService: UserWebService,
    private organizacaoService: OrganizacaoDataService,
    private cacheService: CacheService,
    private router: Router,
    private themeService: ThemeSharedService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const organizacaoId = this.localStorage.getOrganizacaoId();
    this.organizacaoService.getOrganizacaoAssinatura(organizacaoId).subscribe((res) => {
      const assinatura = res;
      let user = this.localStorage.getUser();
      user.organizacoes[0]['assinatura'] = assinatura;
      this.cacheService.setOrganizacaoAssinatura(assinatura);

      this.localStorage.setObject('user', user);
      this.localStorage.setObject(
        'organizacao_plano',
        assinatura.organizacao_plano.data.label
      );
      if (assinatura.inadimplente) {
        this.themeService.setHideMenu(false);
        return this.router.navigate(['/pages/erro-assinatura']);
      }
    });
    return of(true);
  }

  getPermissions() {
    return this.localStorage.getUserPermissions();
  }
}
