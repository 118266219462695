import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../user/services/local-storage.service';

@Component({
  selector: 'invite-queue',
  templateUrl: '../templates/invite-queue.html',
  styleUrls: ['../scss/invite-queue.scss'],
})
export class InviteQueueComponent implements OnInit {
  user;

  constructor(private localStorage: LocalStorageService) {
    this.user = this.localStorage.getUser();
  }

  ngOnInit() {}
}
