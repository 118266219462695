import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { User } from '../models/user.model';

@Injectable()
export class UserStorageService {
  private readonly USER = 'user';

  constructor(private localStorage: LocalStorageService) {}

  removeUser() {
    this.localStorage.remove(this.USER);
  }

  setUser(user: User) {
    this.localStorage.set(this.USER, JSON.stringify(user));
  }
}
