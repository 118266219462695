export interface ColumnSetting {
  header: string;
  type?: Types;
  dataProperty?: string;
  format?: PipeFormat;
  actions?: ActionSetting[];
  isSortable?: boolean;
  textAndIconProperty?: TextAndIconSetting[];
  styles?: any;
}

export interface ActionSetting {
  description?: string;
  icon?: string;
  callback?: any;
  params?: Object;
  class?: string[];
}

export interface TextAndIconSetting {
  icon: any;
  styleCell?: any;
  styleData?: any;
  styleIcon?: any;
}

export enum PipeFormat {
  DEFAULT, // 0
  CURRENCY, // 1
  DATE, // 2
}

export enum Types {
  Link = 'LINK',
  Text = 'TEXT',
  Date = 'DATE',
  Currency = 'CURRENCY',
  HtmlElement = 'HTML_ELEMENT',
}
