import { Component } from '@angular/core';
import SupportedBrowserRegex from './regex';

@Component({
  selector: 'app-outdated-browser-warning',
  templateUrl: './outdated-browser-warning.component.html',
  styleUrls: ['./outdated-browser-warning.component.scss'],
})
export class OutdatedBrowserWarningComponent {
  enabled = !SupportedBrowserRegex.test(navigator.userAgent);
  iOS = navigator.platform && /iPad|iPhone|iPod|iOS/.test(navigator.platform);
  macOS = navigator.platform && navigator.platform.indexOf('Mac') === 0;
  android = /android/i.test(navigator.userAgent);
  linux = !this.android && /linux/i.test(navigator.userAgent);
}
