import { Injectable } from '@angular/core';

import { LocalStorageService } from '../../../../pages/user/services/local-storage.service';
import { MenuItemChild } from '../models/menu-item-child.model';

@Injectable()
export class MenuUtilsService {
  constructor(private localStorage: LocalStorageService) {}

  userHavePermission(_permission): boolean {
    const permission = this.localStorage
      .getObject('user')
      .permissoes.find((p) => p === _permission);
    return permission ? true : false;
  }

  calculateSubmenuHeight(_children: Array<MenuItemChild>): string {
    let pxValue: number = 24;

    _children.forEach((child) => {
      if (child.visible) {
        pxValue += 32;
      }
    });

    return `${pxValue}px`;
  }
}
