import { environment } from './../../../environments/environment';
import { ThemeSharedService } from './../../theme/services/theme.shared.service';
import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(private themeService: ThemeSharedService) {}

  handleError(error) {
    let message = 'Erro genérico';

    const body = error?._body ? JSON.parse(error._body) : error;
    if (typeof body.messages !== 'undefined' && body.messages !== null) {
      message = body.messages[0];
    }

    Sentry.captureEvent({
      exception: { values: [{ type: message }] },
      extra: body,
    });
  }
}
