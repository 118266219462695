import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

type Props = {
  method: string;
  control: string;
};

@Injectable({ providedIn: 'root' })
export class IcpSelectService {
  private subject = new Subject<Props>();

  action(props: Props) {
    this.subject.next(props);
  }

  callback(): Observable<Props> {
    return this.subject.asObservable();
  }
}
