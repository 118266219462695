import { Component } from '@angular/core';

import { BaseMenuItemComponent } from '../base-menu-item.component';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';

@Component({
  selector: 'simulador-de-vendas-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss'],
})
export class SimuladorDeVendasMenuItem extends BaseMenuItemComponent {
  menuCollapsed: boolean;

  constructor(menuSharedService: MenuSharedService, menuUtilsService: MenuUtilsService) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Simulador de vendas',
      '/pages/simulador-vendas',
      [],
      `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10.56 3.53C10.85 3.24 11.33 3.24 11.62 3.53L12.5 4.41L13.38 3.53C13.67 3.24 14.15 3.24 14.44 3.53C14.73 3.82 14.73 4.3 14.44 4.59L13.56 5.47L14.44 6.35C14.73 6.64 14.73 7.12 14.44 7.41C14.15 7.7 13.67 7.7 13.38 7.41L12.5 6.54L11.62 7.42C11.33 7.71 10.85 7.71 10.56 7.42C10.27 7.13 10.27 6.65 10.56 6.36L11.44 5.48L10.56 4.6C10.26 4.3 10.26 3.82 10.56 3.53ZM4 4.72H7.5C7.91 4.72 8.25 5.06 8.25 5.47C8.25 5.88 7.91 6.22 7.5 6.22H4C3.59 6.22 3.25 5.88 3.25 5.47C3.25 5.06 3.59 4.72 4 4.72ZM7.75 13H6.5V14.25C6.5 14.66 6.16 15 5.75 15C5.34 15 5 14.66 5 14.25V13H3.75C3.34 13 3 12.66 3 12.25C3 11.84 3.34 11.5 3.75 11.5H5V10.25C5 9.84 5.34 9.5 5.75 9.5C6.16 9.5 6.5 9.84 6.5 10.25V11.5H7.75C8.16 11.5 8.5 11.84 8.5 12.25C8.5 12.66 8.16 13 7.75 13ZM14.25 14.25H10.75C10.34 14.25 10 13.91 10 13.5C10 13.09 10.34 12.75 10.75 12.75H14.25C14.66 12.75 15 13.09 15 13.5C15 13.91 14.66 14.25 14.25 14.25ZM14.25 11.75H10.75C10.34 11.75 10 11.41 10 11C10 10.59 10.34 10.25 10.75 10.25H14.25C14.66 10.25 15 10.59 15 11C15 11.41 14.66 11.75 14.25 11.75Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    );

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
      } else {
        this.showChildren = false;
      }
    });
  }

  async ngOnInit() {
    this.menuSharedService.getMenuCollapsed().subscribe((menuCollapsed) => {
      this.menuCollapsed = menuCollapsed;
      this.heightWhenShowingChildren = menuCollapsed
        ? '0px'
        : this.heightWhenShowingChildren;
    });
  }
}
