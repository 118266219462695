import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { OnboardingRouterGuard } from '../onboarding/router-guards/onboarding-router-guard';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbComponent } from '../theme/components/breadcrumb/breadcrumb.component';
import { MenuComponent } from '../theme/components/menu/menu.component';
import { MenuModule } from '../theme/components/menu/menu.module';
import { MenuSharedService } from '../theme/components/menu/services/menu.shared.service';
import { BaThemeSpinner } from '../theme/services/baThemeSpinner';
import { ThemeSharedService } from '../theme/services/theme.shared.service';
import { StepService } from './../shared/services/step.service';
import { ErroAssinatura } from './assinatura/components/erro-assinatura.component';
import { PagamentoAssinaturaComponent } from './assinatura/components/pagamento-assinatura.component';
import { SelecaoComponent } from './assinatura/components/selecao.component';
import { AssinaturaService } from './assinatura/services/assinatura.service';
import { LoteService } from './gestao-financeira/dashboard/services/lote.service';
import { Pages } from './pages.component';
import { routing } from './pages.routing';
import { InviteQueueComponent } from './referral/components/invite-queue.component';
import { AuthService } from './user/services/auth.service';
import { CombinedGuards } from './user/services/combined-guards.service';
import { DataSharingService } from './user/services/data-sharing.web.service';
import { DefaultRequestOptionsService } from './user/services/default-request-options.service';
import { EmailConfirmationService } from './user/services/email-confirmation.service';
import { HandleLoggedUserGuard } from './user/services/handle-logged-user-guard.service';
import { LocalStorageService } from './user/services/local-storage.service';
import { LoginService } from './user/services/login.service';
import { OAuthService } from './user/services/oauth.service';
import { Oauth2TokenService } from './user/services/oauth2-token.service';
import { OrganizacaoDataService } from './user/services/organizacao-data.web.service';
import { OrganizacaoOnboardingStepService } from './user/services/organizacao-onboarding-step.service';
import { RefreshLoginGuard } from './user/services/refresh-login-guard.service';
import { RoutePermissionGuard } from './user/services/route-permission-guard.service';
import { UpdateUserGuard } from './user/services/update-user-guard.service';
import { UserStorageService } from './user/services/user-storage.service';
import { UserWebService } from './user/services/user.web.service';

@NgModule({
  imports: [
    CommonModule,
    routing,
    SharedModule,
    FormsModule,
    MenuModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    Pages,
    MenuComponent,
    BreadcrumbComponent,
    InviteQueueComponent,
    SelecaoComponent,
    PagamentoAssinaturaComponent,
    ErroAssinatura,
  ],
  providers: [
    AuthService,
    LoginService,
    Oauth2TokenService,
    LocalStorageService,
    DefaultRequestOptionsService,
    UserWebService,
    OAuthService,
    UserStorageService,
    RefreshLoginGuard,
    RoutePermissionGuard,
    OrganizacaoDataService,
    OrganizacaoOnboardingStepService,
    BaThemeSpinner,
    DataSharingService,
    ThemeSharedService,
    LoteService,
    EmailConfirmationService,
    CombinedGuards,
    AssinaturaService,
    UpdateUserGuard,
    MenuSharedService,
    HandleLoggedUserGuard,
    OnboardingRouterGuard,
    StepService,
  ],
})
export class PagesModule {}
