import { OrganizacaoRoutes } from './organizacao.routes.constants';
import { PagesRoutes } from './pages.routes.constants';
import { AntecipacaoRoutes } from './antecipacao.routes.constants';
import { DashboardRoutes } from './dashboard.routes.constants';

export class AppRoutes {
  private static readonly ORGANIZACAO = new OrganizacaoRoutes(
    'cadastro-organizacao',
    'cadastro-unidade',
    'cadastro-contato',
    'cadastro-conta',
    'cadastro-completo',
    'cadastro-medico',
    'cadastro-plano'
  );
  private static readonly ANTECIPACAO = new AntecipacaoRoutes(
    'antecipacao-fatura',
    'list',
    'validation',
    'confirmation',
    'confirmation-all'
  );
  private static readonly DASHBOARD = new DashboardRoutes('dashboard');
  public static readonly PAGES = new PagesRoutes(
    'pages',
    AppRoutes.ORGANIZACAO,
    AppRoutes.ANTECIPACAO,
    AppRoutes.DASHBOARD
  );

  public static readonly INTRO = 'intro';
  public static readonly LOGIN = 'login';
  public static readonly PRIVACY_POLICY = 'politica-privacidade';
  public static readonly LOGOUT = 'logout';
  public static readonly REGISTER = 'register';
  public static readonly RECEIVING_SOURCE = 'receiving-source';
  public static readonly FORGOT_PASSWORD = 'forgot-password';
  public static readonly RESET_PASSWORD = 'reset-password/:token';
  public static readonly CHANGE_PASSWORD = 'change-password';
  public static readonly EMAIL_CONFIRMATION = 'email-confirmation';
  public static readonly AUTH_SSO = 'auth/sso';
  public static readonly ONBOARDING = 'onboarding';
}
