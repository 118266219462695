import { Injectable } from '@angular/core';

import { TPersonEnum, TOnboardingScreen } from '../types/onboarding.types';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor() {}

  private dataSource: Record<string, TPersonEnum | object>;

  setData(data: Record<string, TPersonEnum | object>) {
    this.dataSource = { ...this.dataSource, ...data };
  }

  getData(screen: TOnboardingScreen) {
    return this.dataSource[screen];
  }

  getAll() {
    return this.dataSource;
  }

  reset() {
    this.dataSource = undefined;
  }

  remove(screen: TOnboardingScreen) {
    if (this.dataSource && this.dataSource[screen]) {
      delete this.dataSource[screen];
    }
  }

  getStepData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.STEP_ONBOARDING]) ?? undefined
    );
  }

  getOrganizationData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.ORGANIZATION_ONBOARDING]) ??
      undefined
    );
  }

  getUserData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.USER_ONBOARDING]) ?? undefined
    );
  }

  getReceiveSource() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.RECEIVE_SOURCE_ONBOARDING]) ??
      undefined
    );
  }

  getPlanData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.PLAN_ONBOARDING]) ?? undefined
    );
  }

  getLoginData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.LOGIN_DATA_ONBOARDING]) ??
      undefined
    );
  }

  getPersonType() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.PERSON_TYPE_ONBOARDING]) ??
      undefined
    );
  }

  getCompanyData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.COMPANY_ONBOARDING]) ??
      undefined
    );
  }

  getPersonData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.PERSON_ONBOARDING]) ??
      undefined
    );
  }

  getAddressData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.ADDRESS_ONBOARDING]) ??
      undefined
    );
  }

  getAccountData() {
    return (
      (this.dataSource && this.dataSource[TOnboardingScreen.ACCOUNT_ONBOARDING]) ??
      undefined
    );
  }
}
