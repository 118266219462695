export class Alert {
  id: string;
  type: AlertType;
  message: string;
  autoClose: boolean;
  fade: boolean;
  placement: PlacementType;
  icon: string;

  constructor(init?: Partial<Alert>) {
    init.placement = init.placement ? init.placement : PlacementType.TopRight;
    init.type = init.type ? init.type : AlertType.Info;
    init.icon = init.type ? init.icon : AlertType.Info;
    init.autoClose = init.autoClose ?? true;

    Object.assign(this, init);
  }
}

export enum AlertType {
  Success = 'success',
  Error = 'danger',
  Warning = 'warning',
  Info = 'info',
}

export enum PlacementType {
  TopLeft = 'top-left',
  Top = 'top',
  TopRight = 'top-right',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  BottomLeft = 'bottom-left',
  Bottom = 'bottom',
  BottomRight = 'bottom-right',
}

export enum AlertIcon {
  Success = 'check_circle',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
}
