import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { LoginService } from './login.service';
import { flatMap, first } from 'rxjs/operators';
import { CacheService } from '../../../shared/services/cache.service';

@Injectable()
export class RefreshLoginGuard implements CanActivate {
  public check: boolean;

  constructor(
    private router: Router,
    private http: HttpInterceptor,
    private localStorage: LocalStorageService,
    private loginService: LoginService,
    private cacheService: CacheService
  ) {}

  get organizacaoId() {
    return this.localStorage.getObject('user').organizacoes[0].id;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getDados().pipe(
      flatMap((res) => {
        if (res && res.error) {
          this.localStorage.setObject('urlToRedirect', state.url);
          if (this.router.url === '/login') {
            location.reload();
          }
          this.router.navigate(['login']);
          return of(false);
        }
        return of(true);
      }),
      first()
    );
  }

  checkLogin() {
    return this.loginService.isLogged().subscribe(
      (res) => {
        this.loginSuccess(res);
      },
      (res) => {
        this.loginFailure(res);
      }
    );
  }

  loginSuccess(res) {
    return true;
  }

  loginFailure(res) {
    this.router.navigate(['login']);
  }

  getDados() {
    return this.cacheService.getRefreshData();
  }
}
