import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../../pages/user/services/login.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ThemeSharedService } from '../../../../theme/services/theme.shared.service';

@Component({
  selector: 'generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericError implements OnInit {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private _location: Location,
    private themeService: ThemeSharedService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      window.history.back();
      window.location.reload();
    }, 3000);
  }

  redirect() {
    const location = this._location;
    const themeService = this.themeService;
    setTimeout(function () {
      location.back();
      themeService.setGenericErrorHandler(false);
    }, 2000);
  }
}
