import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MenuSharedService {
  showingSubItemsOf: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  menuCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // Mostrar submenu ativo
  setSubItemsOf(title) {
    this.showingSubItemsOf.next(title);
  }

  getSubItemsOf() {
    return this.showingSubItemsOf.asObservable();
  }

  // Checar se menu está aberto ou fechado
  setMenuCollapsed(value) {
    this.menuCollapsed.next(value);
  }

  getMenuCollapsed() {
    return this.menuCollapsed.asObservable();
  }
}
