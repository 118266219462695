import { Injectable } from '@angular/core';
import { Headers, Response } from '@angular/http';

import { OAuthService } from '../services/oauth.service';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class EmailConfirmationService {
  private headers;

  constructor(
    private http: HttpInterceptor,
    private localStorage: LocalStorageService,
    private oauthService: OAuthService
  ) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  confirmEmail(token: string) {
    let url = environment.api.base_api + APIRoutes.CONFIRMACAO_EMAIL + '/' + token;
    return this.http.get(url, { headers: this.headers }).pipe(map((res: any) => res));
  }

  resendEmail() {
    let url = environment.api.base_api + APIRoutes.REENVIO_EMAIL;
    let accessToken = this.oauthService.getTokens().accessToken;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);
    return this.http.get(url, { headers: headers }).pipe(map((res: any) => res));
  }
}
