import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { IcpAlertService } from './icp-alert.service';
import { Alert, PlacementType } from './icp-alert.model';
import { DetectDeviceService } from '../../../../services/detect-device.service';

@Component({
  selector: 'icp-alert',
  templateUrl: './icp-alert.component.html',
  styleUrls: ['./icp-alert.component.scss'],
})
export class IcpAlertComponent implements OnInit, OnDestroy {
  fade = true;
  alertSubscription: Subscription;
  isMobile: boolean;

  alerts = {
    'top-left': [],
    top: [],
    'top-right': [],
    left: [],
    middle: [],
    right: [],
    'bottom-left': [],
    bottom: [],
    'bottom-right': [],
  };

  placements = [];

  constructor(
    private alertService: IcpAlertService,
    private detectDevice: DetectDeviceService
  ) {
    this.placements = Object.keys(this.alerts);
  }

  ngOnInit(): void {
    this.isMobile = this.detectDevice.checkDevice() === 'mobile';
    this.alertSubscription = this.alertService.getAlerts().subscribe((alert) => {
      if (this.isMobile) {
        alert.placement = PlacementType.Top;
      }

      this.alerts[alert.placement].push(alert);

      if (alert.autoClose) {
        setTimeout(() => this.removeAlert(alert), 3000);
      }
    });
  }

  ngOnDestroy() {
    this.alertSubscription.unsubscribe();
  }

  removeAlert(alert: Alert) {
    if (!this.alerts[alert.placement].includes(alert)) return;

    if (this.fade) {
      this.alerts[alert.placement].find((object) => object === alert).fade = true;

      setTimeout(() => {
        this.alerts[alert.placement] = this.alerts[alert.placement].filter(
          (object) => object !== alert
        );
      }, 250);
    } else {
      this.alerts[alert.placement] = this.alerts[alert.placement].filter(
        (object) => object !== alert
      );
    }
  }

  onClose(alert: Alert) {
    this.alerts[alert.placement] = this.alerts[alert.placement].filter(
      (object) => object !== alert
    );
  }
}
