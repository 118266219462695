import { BaseRoutes } from './base.routes.constants';
import { AntecipacaoListRoutes } from './antecipacao-list.routes.constants';
import { AntecipacaoConfirmationRoutes } from './antecipacao-confirmation.routes.constants';
import { AntecipacaoValidationRoutes } from './antecipacao-validation.routes.constants';
import { AntecipacaoConfirmationAllRoutes } from './antecipacao-confirmation-all.routes.constants';

export class AntecipacaoRoutes extends BaseRoutes {
  public LISTAGEM: AntecipacaoListRoutes;
  public VALIDACAO: AntecipacaoValidationRoutes;
  public CONFIRMACAO: AntecipacaoConfirmationRoutes;
  public CONFIRMACAO_ALL: AntecipacaoConfirmationAllRoutes;

  constructor(
    route: string,
    listagemRoute: string,
    validacaoRoute: string,
    confirmacaoRoute: string,
    confirmacaoAllRoute: string
  ) {
    super(route);
    this.LISTAGEM = new AntecipacaoListRoutes(this.appendPath(this.BASE, listagemRoute));
    this.VALIDACAO = new AntecipacaoValidationRoutes(
      this.appendPath(this.BASE, validacaoRoute)
    );
    this.CONFIRMACAO = new AntecipacaoConfirmationRoutes(
      this.appendPath(this.BASE, confirmacaoRoute)
    );
    this.CONFIRMACAO_ALL = new AntecipacaoConfirmationAllRoutes(
      this.appendPath(this.BASE, confirmacaoAllRoute)
    );
  }

  preAppend(route: string) {
    super.preAppend(route);
    this.LISTAGEM.preAppend(route);
    this.VALIDACAO.preAppend(route);
    this.CONFIRMACAO.preAppend(route);
    this.CONFIRMACAO_ALL.preAppend(route);
  }
}
