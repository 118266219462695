import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { APIRoutes } from '../../../../shared/constants/api-routes.constant';
import { LocalStorageService } from '../../../user/services/local-storage.service';
import { environment } from '../../../../../environments/environment';
import { SearchResult } from '../../../../shared/models/search-result.model';
import { HttpInterceptor } from '../../../../shared/interceptors/http-interceptor.service';
import { AuthenticatedWebService } from '../../../../shared/services/authenticated.web.service';
import { OAuthService } from '../../../user/services/oauth.service';
import { Lote } from '../models/lote.model';

@Injectable()
export class LoteWebService extends AuthenticatedWebService {
  constructor(
    private http: HttpInterceptor,
    oauthService: OAuthService,
    private localStorage: LocalStorageService
  ) {
    super(oauthService);
  }

  getDados(params): Observable<SearchResult> {
    let url = environment.api.base_api + APIRoutes.LOTE;
    params.set('organizacao_id', this.localStorage.getOrganizacaoId());

    return this.http.get(url, { params: params, headers: this.httpHeaders }).pipe(
      map((res: any) => res),
      map((res) => {
        let result = Array<Lote>();
        res.data.forEach((e) => {
          result.push(
            new Lote(
              e.id,
              e.numero,
              e.data && e.data.date ? e.data.date : e.data,
              e.data_pagamento_demonstrativo && e.data_pagamento_demonstrativo.date
                ? e.data_pagamento_demonstrativo.date
                : e.data_pagamento_demonstrativo,
              e.valor_informado,
              e.valor_liberado,
              e.emprestimos && e.emprestimos.data.length ? 'Sim' : 'Não',
              e.medico_plano_saude ? e.medico_plano_saude : null,
              e.data_pagamento_calculada && e.data_pagamento_calculada.date
                ? e.data_pagamento_calculada.date
                : e.data_pagamento_calculada,
              undefined,
              e.plano_saude && e.plano_saude.data.nome,
              e.valor_glosa_calculado,
              e.valor_disponibilizado && e.valor_disponibilizado
            )
          );
        });
        let searchResult = new SearchResult(result, res.meta.pagination);

        return searchResult;
      })
    );
  }

  getSingle(id: string): Observable<Lote> {
    let local = this.localStorage.getObject('user');
    let url = environment.api.base_api + APIRoutes.LOTE;
    let params = new HttpParams()
      .set('organizacao_id', local.organizacoes[0].id)
      .set('include', 'emprestimos,medico_plano_saude,medico_plano_saude.plano_saude');

    if (id) {
      params.set('id', id);
    }

    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data));
  }
}
