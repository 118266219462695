import { Component, EventEmitter, Output } from '@angular/core';

import { BaseMenuItemComponent } from '../base-menu-item.component';
import { MenuItemChild } from '../models/menu-item-child.model';
import { MenuUtilsService } from '../services/menu-utils.service';
import { MenuSharedService } from '../services/menu.shared.service';

@Component({
  selector: 'gestao-financeira-menu-item',
  templateUrl: '../templates/menu-item.html',
  styleUrls: ['../scss/menu-item.scss'],
})
export class GestaoFinanceiraMenuItem extends BaseMenuItemComponent {
  @Output() onClickSelect = new EventEmitter<any>();
  @Output() onClickItem = new EventEmitter<void>();
  menuCollapsed: boolean;
  isMobile: boolean = false;

  constructor(menuSharedService: MenuSharedService, menuUtilsService: MenuUtilsService) {
    // Título, Array de Filhos, SVG, Ativo (sim ou não), altura da lista quando os subitems estiverem à mostra
    super(
      'Gestão financeira',
      'gestao-financeira',
      [
        new MenuItemChild(
          'Resumo',
          '/pages/gestao-financeira/dashboard/resumo',
          false,
          true
        ),
        new MenuItemChild(
          'Faturamento',
          '/pages/gestao-financeira/dashboard/faturamento',
          false,
          true
        ),
        new MenuItemChild(
          'Glosa',
          '/pages/gestao-financeira/dashboard/glosa',
          false,
          true
        ),
        new MenuItemChild(
          'Pagamento',
          '/pages/gestao-financeira/dashboard/pagamento',
          false,
          true
        ),
        new MenuItemChild(
          'Conciliação',
          '/pages/gestao-financeira/conciliacao',
          false,
          true
        ),
      ],
      `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM4 7H6V14H4V7ZM8 4H10V14H8V4ZM12 10H14V14H12V10Z" fill="#A0CFF8"/>
      </svg>`,
      false,
      false,
      '0px',
      menuSharedService,
      menuUtilsService
    );

    this.menuSharedService.getSubItemsOf().subscribe((title) => {
      if (title === this.title && !this.showChildren) {
        this.showChildren = true;
        this.heightWhenShowingChildren = `${this.children.length * 32 + 40}px`;
      } else {
        this.showChildren = false;
        this.heightWhenShowingChildren = '0px';
      }
    });
  }

  async ngOnInit() {
    if (window.innerWidth <= 500 || screen.width <= 500) {
      this.isMobile = true;
    }

    this.menuSharedService.getMenuCollapsed().subscribe((menuCollapsed) => {
      this.menuCollapsed = menuCollapsed;
      this.heightWhenShowingChildren =
        menuCollapsed || this.isMobile ? '0px' : this.heightWhenShowingChildren;

      if (this.menuCollapsed && this.showChildren) {
        this.showChildren = false;
      }
    });
  }

  toggleItemChildren() {
    super.toggleItemChildren();
    this.onClickSelect.emit({ title: this.title, children: this.children });
    this.isMobile = window.innerWidth <= 500 || screen.width <= 500 ? true : false;
  }

  menuItemClicked() {
    super.menuItemClicked();
    this.onClickItem.emit();
  }
}
