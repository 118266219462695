import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { LocalStorageService } from './local-storage.service';
import { URLSearchParams } from '@angular/http';
import { OrganizacaoDataService } from './organizacao-data.web.service';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { flatMap, map, first, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class DataSharingService {
  private organizacaoData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private terminalLiberacaoData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshTokenData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private loggedUserAvatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private createdPlan: ReplaySubject<any> = new ReplaySubject<any>(null);
  private createdSeller: ReplaySubject<any> = new ReplaySubject<any>(null);
  private routerGuardData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private localStorage: LocalStorageService,
    private organizacaoDataService: OrganizacaoDataService,
    private loginService: LoginService,
    private router: Router
  ) {}

  get organizacaoId() {
    return this.localStorage.getObject('user').organizacoes[0].id;
  }

  getOrganizacaoData(): Observable<any> {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(['login']);
    return this.organizacaoData.asObservable().pipe(
      take(1),
      flatMap((res) => {
        if (
          res === null ||
          res.data.organizacao_id !== this.localStorage.getOrganizacaoId()
        ) {
          return this.organizacaoDataService
            .getData(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setOrganizacaoData(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  getCobrancaAssinatura() {
    return this.organizacaoDataService.getCobrancaAssinatura(
      this.localStorage.getOrganizacaoId()
    );
  }

  setOrganizacaoData(newData: any) {
    this.organizacaoData.next(newData);
  }

  setRouterGuardData(data: any) {
    return this.routerGuardData.next(data);
  }

  getRouterGuardData() {
    return this.routerGuardData.asObservable().pipe(first());
  }

  getRefreshData(): Observable<any> {
    return this.refreshTokenData.asObservable().pipe(
      flatMap((res) => {
        if (res === null) {
          return this.loginService.isLogged().pipe(
            map((res) => {
              this.setRefreshData(res);
              return res;
            })
          );
        }
        return of(res);
      })
    );
  }

  setRefreshData(newData: any) {
    this.refreshTokenData.next(newData);
  }

  getTerminalLiberacaoData(params?: URLSearchParams) {
    if (!this.localStorage.getOrganizacaoId()) this.router.navigate(['login']);
    return this.terminalLiberacaoData.asObservable().pipe(
      flatMap((res) => {
        if (res === null) {
          return this.organizacaoDataService
            .getTerminalLiberacao(this.localStorage.getOrganizacaoId())
            .pipe(
              map((res) => {
                this.setTerminalLiberacaoData(res);
                return res;
              })
            );
        }
        return of(res);
      }),
      first()
    );
  }

  setTerminalLiberacaoData(newData: any) {
    this.terminalLiberacaoData.next(newData);
  }

  getLoggedUserAvatar() {
    return this.loggedUserAvatar.asObservable();
  }

  setLoggedUserAvatar(url: any) {
    this.loggedUserAvatar.next(url);
  }

  setCreatedPlan(data: any) {
    this.createdPlan.next(data);
  }

  getCreatedPlan() {
    return this.createdPlan.asObservable();
  }

  setCreatedSeller(data: any) {
    this.createdSeller.next(data);
  }

  getCreatedSeller() {
    return this.createdSeller.asObservable();
  }
}
