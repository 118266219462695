import { Identifiable } from '../../../shared/models/identifiable.model';
import { Vendedor } from '../../dados-cadastrais/particular/models/vendedor.model';

export class Organizacao implements Identifiable {
  id: string;
  url: string;
  name: string;
  authorized: boolean;
  telefone: string;
  organizacao_status_id: string;
  created_at: string;
  referral: string;

  constructor(
    id?: string,
    url?: string,
    name?: string,
    authorized?: boolean,
    telefone?: string,
    organizacao_status_id?: string,
    created_at?: string,
    referral?: string
  ) {
    this.id = id;
    this.url = url;
    this.name = name;
    this.authorized = authorized;
    this.telefone = telefone;
    this.organizacao_status_id = organizacao_status_id;
    this.created_at = created_at;
    this.referral = referral;
  }
}

export interface OrganizacaoOnboardingStep {
  organization_id: string;
  organization_name: string;
  seller_amount: number;
  last_seller_id: string;
  plan_log_pro_amount: number;
  individual_seller_address_amount: number;
  business_seller_address_amount: number;
  business_seller_owner_address_amount: number;
  document_amount: number;
  account_amount: number;
  plan_amount: number;
  operational_agreement_amount: number;
  physician_health_plan_amount: number;
  seller_id: string;
  flow: Array<string>;
  step: string;
  transaction_anticipation_allowed: boolean;
  sellers: Array<any>;
}
