export class MenuItemChild {
  title: string;
  route: string;
  active: boolean;
  visible: boolean;

  constructor(title?: string, route?: string, active?: boolean, visible?: boolean) {
    this.title = title;
    this.route = route;
    this.active = active;
    this.visible = visible;
  }
}
