import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { OAuthService } from '../../user/services/oauth.service';
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { AuthenticatedWebService } from '../../../shared/services/authenticated.web.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class AssinaturaService extends AuthenticatedWebService {
  constructor(
    private http: HttpInterceptor,
    oauthService: OAuthService
  ) {
    super(oauthService);
  }

  getPlans(params): Observable<Array<any>> {
    let url = environment.api.base_api + APIRoutes.COBRANCA_MODALIDADE;

    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data));
  }

  generatePaymentLink(body): any {
    let url = environment.api.base_api + APIRoutes.GERAR_COBRANCA_ASSINATURA;

    return this.http
      .post(url, body, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }
}
