import { environment } from '../../../environments/environment';

let webpSupport = false;

// Teste de suporte de WebP
const img = new Image();
img.onload = function () {
  webpSupport = img.width > 0 && img.height > 0;
};
img.src =
  'data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==';

export const getImageUrl = (id, width?, height?, lossless?) => {
  const retinaMultiplier = window.devicePixelRatio || 1;
  const realWidth = width ? Math.ceil(width * retinaMultiplier) : '';
  const realHeight = height ? Math.ceil(height * retinaMultiplier) : '';
  let environmentPrefix = '';
  if (environment.name !== 'prod') environmentPrefix = environment.name + '.';
  if (environment.name === 'local') environmentPrefix = 'feature.';
  const lossyExt = webpSupport ? 'webp' : 'jpg';
  const losslessExt = webpSupport ? 'webp_lossless' : 'png';
  const ext = lossless ? losslessExt : lossyExt;
  return `https://${environmentPrefix}images.iclinicpay.com.br/${realWidth}x${realHeight}/${id}.${ext}`;
};

export default getImageUrl;
