import { Injectable } from '@angular/core';
import { GlobalConfig } from '../../../shared/configs/global-config';

@Injectable()
export class LocalStorageService {
  constructor() {}

  set(key, value) {
    window.localStorage[key] = value;
  }

  get(key, defaultValue = null) {
    return window.localStorage[key] || defaultValue;
  }

  setObject(key, value: Object) {
    this.set(key, JSON.stringify(value));
  }

  getObject(key) {
    return JSON.parse(window.localStorage.getItem(key));
  }

  remove(key) {
    window.localStorage.removeItem(key);
  }

  getUser() {
    let user = this.getObject('user');
    if (!user) return;
    return user;
  }

  getOrganizacaoId() {
    let user = this.getObject('user');
    if (!user) return;
    return user.organizacoes[0].id;
  }

  getOrganizacaoPlanoId() {
    let user = this.getObject('user');
    if (!user) return;
    return user.organizacoes[0].assinatura.organizacao_plano_id;
  }

  getOrganizacaoAuthorized() {
    let user = this.getObject('user');
    if (!user) return;
    return user.organizacoes[0].authorized;
  }

  getOrganizacaoIsento() {
    let user = this.getObject('user');
    if (!user) return;
    return user.organizacoes[0].isento;
  }

  get organizacaoCanChangePlan() {
    return true;
  }

  getRole() {
    let user = this.getObject('user');
    if (!user) return;
    return user.role_name;
  }

  getUserRoleLevel() {
    let user = this.getObject('user');
    if (!user) return;
    return user.role_level;
  }

  getUserPermissions() {
    let user = this.getObject('user');
    if (!user) return [];
    return user.permissoes;
  }
}
