import { ContentLoadService } from './../../../shared/services/content-load.service';

import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { AuthenticatedWebService } from '../../../shared/services/authenticated.web.service';
import { OAuthService } from './oauth.service';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class OrganizacaoDataService extends AuthenticatedWebService {
  constructor(
    private http: HttpInterceptor,
    oauthService: OAuthService,
    private router: Router,
    private contentLoadService: ContentLoadService
  ) {
    super(oauthService);
  }

  getData(organizacaoId: string) {
    if (!organizacaoId) {
      this.router.navigate(['login']);
      return observableThrowError(new Error('Nenhum usuário logado'));
    }

    const url = `${environment.api.base_url}api/v2/organizations/${organizacaoId}/onboarding-step`;

    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      map((res: any) => res),
      catchError((err) => {
        if (err.error.error === 'Unauthenticated.') {
          this.contentLoadService.setLoadingData([' ', false]);
          this.router.navigate(['login']);
        }
        throw new Error(err);
      })
    );
  }

  getTerminalLiberacao(organizacao_id: string) {
    let params = new HttpParams()
      .set('organizacao_id', organizacao_id)
      .set('orderBy', 'data_inicio')
      .set('sortBy', 'asc');
    let url = environment.api.base_api + APIRoutes.TRANSACAO_LIBERACAO;
    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  getOrganizacaoAssinatura(organizacao_id: string) {
    let params = new HttpParams()
      .set('organizacao_id', organizacao_id)
      .set('include', 'organizacao_plano');
    let url = environment.api.base_api + APIRoutes.ORGANIZACAO_ASSINATURA_PLANO;
    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data[0]));
  }

  getCobrancaAssinatura(organizacao_id: string) {
    let params = new HttpParams()
      .set('organizacao_id', organizacao_id)
      .set('atual', 'true')
      .set('include', 'cobranca_modalidade');
    let url = environment.api.base_api + APIRoutes.COBRANCA_ASSINATURA;
    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  setUserCobranca(user) {
    let params = new HttpParams()
      .set('organizacao_id', user.organizacoes[0].id)
      .set('atual', 'true');
    let url = environment.api.base_api + APIRoutes.COBRANCA_ASSINATURA;
    return this.http.get(url, { params: params, headers: this.httpHeaders }).pipe(
      map((res: any) => res),
      map((assinatura) => {
        user['assinatura'] = assinatura.data[0];
        return user;
      })
    );
  }
}
