import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { GlobalConfig } from './app/shared/configs/global-config';

if (location.hostname !== 'localhost') {
  enableProdMode();
}

setTimeout(() => {
  localStorage.setItem('cookieConsent', environment.cookieConsent);
}, GlobalConfig.MOUSEENTER_TIMEOUT);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
