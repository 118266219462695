import { Injectable } from '@angular/core';
import { NoOrganizationError } from '../models/user.errors';
import { OAuthService } from '../services/oauth.service';
import { BaseWebService } from '../../../shared/services/base.service';
import { HttpInterceptor } from '../../../shared/interceptors/http-interceptor.service';
import { User } from '../models/user.model';
import { Organizacao } from '../models/organizacao.model';
import { APIRoutes } from '../../../shared/constants/api-routes.constant';
import { RegisterModel } from '../models/register.model';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UserWebService extends BaseWebService<User> {
  constructor(http: HttpInterceptor, oauthService: OAuthService) {
    super(APIRoutes.USER_LIST, http, oauthService);
  }

  newElement(data: any): User {
    let orgData = data.organizacoes.data[0];
    if (!orgData) {
      throw new NoOrganizationError('User does not have an organization.');
    }
    let orgs = [
      new Organizacao(
        orgData.id,
        orgData.url,
        orgData.nome,
        orgData.autorizado,
        orgData.telefone,
        orgData.organizacao_status_id,
        orgData.created_at,
        orgData.referral
      ),
    ];
    return new User(
      data.id,
      data.name,
      data.email,
      null,
      null,
      orgs,
      data.data_confirmacao_email,
      data.permissions,
      undefined,
      undefined,
      undefined,
      data.photo_id,
      undefined,
      undefined,
      data.role.name,
      data.role.level,
      data.role.description,
      data.role.color
    );
  }

  getSelf(includePermissions = false, accessTokenSSO?, refreshToken?) {
    if (accessTokenSSO) {
      localStorage.setItem('access_token', accessTokenSSO);
      localStorage.setItem('refresh_token', refreshToken);
    }

    let accessToken = accessTokenSSO
      ? accessTokenSSO
      : this['oauthService'].getTokens().accessToken;

    this.httpHeaders.set('Content-Type', 'application/json');
    this.httpHeaders.set('Authorization', `Bearer ${accessToken}`);

    let url = this.BASE_URL + APIRoutes.SELF_USER;
    url += includePermissions ? '?include=permissao' : '';

    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      map((res: any) => res.data[0]),
      map((res) => this.newElement(res))
    );
  }

  getUserToEdit(params) {
    let url = this.BASE_URL + APIRoutes.USER_LIST;
    return this.http
      .get(url, { params: params, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data[0]));
  }

  getUserRole(id) {
    let url = this.BASE_URL + APIRoutes.USER_LIST + '/' + id + '/' + APIRoutes.PERFIL;
    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      map((res: any) => {
        return res.data[0];
      })
    );
  }

  editUser(data, user) {
    let params = new HttpParams().set('organizacao_id', user.organizacoes[0].id);
    let url = this.BASE_URL + APIRoutes.USER_LIST + '/' + user.id;
    return this.http
      .post(url, data, { params: params, headers: this.httpHeadersFormData })
      .pipe(map((res: any) => res));
  }

  removeUser(id) {
    let url = this.BASE_URL + APIRoutes.USER_LIST + '/' + id;
    return this.http
      .delete(url, { headers: this.httpHeadersFormData })
      .pipe(map((res: any) => res));
  }

  createNewMember(data) {
    let url = this.BASE_URL + APIRoutes.USER_LIST;
    return this.http
      .post(url, data, { headers: this.httpHeadersFormData })
      .pipe(map((res: any) => res));
  }

  getPermissions(user?) {
    let accessToken = this['oauthService'].getTokens().accessToken;
    this.httpHeaders.set('Content-Type', 'application/json');
    this.httpHeaders.set('Authorization', `Bearer ${accessToken}`);

    let url = this.BASE_URL + `user/web/permission`;
    return this.http.get(url, { headers: this.httpHeaders }).pipe(
      map((res: any) => res.data),
      map((permissoes) => {
        if (user) {
          user.permissoes = permissoes;
          return user;
        }
        return permissoes;
      })
    );
  }

  registerUser(register: RegisterModel) {
    let url = environment.api.base_api + APIRoutes.USER_REGISTER;
    return this.http
      .post(url, register, { withCredentials: true })
      .pipe(map((res: any) => res));
  }

  getUserLimit(organizacaoId) {
    let url = `${this.BASE_URL}${APIRoutes.ORGANIZACAO}/${organizacaoId}/${APIRoutes.LIMITE_USUARIO}`;
    return this.http
      .get(url, { params: null, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data[0]));
  }
}
